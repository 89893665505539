
/**
 * This component is just a transparent wrapper over RtbImageUploader,
 * taht passes UplaoderImpl instance to it
 */
import Vue, { PropType } from "vue"
import { v4 } from "uuid"

import { RtbImageUploader } from "@/components/ui"
import {
  Constraints,
  ImageUploderError
} from "@/components/ui/ImageUploder/types"

import Uploader from "@/helpers/uploader"

export default Vue.extend({
  name: "ImageUploader",
  inheritAttrs: false,
  props: {
    constraints: {
      type: Object as PropType<Constraints>,
      default() {
        return {
          width: { min: 2, max: 4096 },
          height: { min: 2, max: 4096 },
          extensions: [
            "jpg",
            "png",
            "jpeg",
            "gif",
            "svg",
            "JPEG",
            "PNG",
            "GIF"
          ],
          size: { min: 2, max: 20971520 }
        }
      }
    }
  },
  data() {
    return {
      uploader: new Uploader(this.$store.state.auth.token),
      error: undefined
    }
  },
  computed: {
    listeners(): Record<string, Function | Function[]> {
      return {
        ...this.$listeners,
        error: (e: ImageUploderError) => {
          const { constraints } = this
          switch (e.type) {
            case "dimension":
              this.error = `Min image size ${constraints.width.min}x${constraints.height.min}px and max ${constraints.width.max}x${constraints.height.max}px`
              break
            case "extension":
              this.error = `Invalid file extension. Supported extensions: ${constraints.extensions.join(
                ", "
              )}`
              break
            case "size":
              this.error = `File is too large. Maximum file size is ${constraints.size} KB`
              break
            case "unknown":
              break
          }
        }
      }
    }
  },
  methods: {
    fileName(file: File) {
      return `gamephotos/${v4()}.png`
    }
  },
  render(h) {
    return h(
      RtbImageUploader,
      {
        props: {
          ...this.$attrs,
          constraints: this.constraints,
          uploader: this.uploader,
          error: this.error,
          fileName: this.fileName
        },
        on: this.listeners
      },
      this.$slots.default
    )
  }
})
